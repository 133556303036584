import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

createApp(App).use(router).mount("#app");
document.title = "Grif's Website";
var link =
  document.querySelector("link[rel*='icon']") || document.createElement("link");
link.href = "https://cdn.icon-icons.com/icons2/2596/PNG/512/dot_icon_155477.png";
document.getElementsByTagName("head")[0].appendChild(link);
