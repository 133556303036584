<template>
  <div class="404">
    <h1 class="main-text">Wait what?</h1>
  </div>
  <div class="desc">
    <p>
      Um, I'm not sure how you ended up here, but you should go back to the home page.
    </p>
  </div>
  <button @click="$router.push('/')" class="btn">Go Back Home</button>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style>
.main-text {
  color: white;
  font-weight: bold;
  font-size: 50px;
}

.word {
  color: rgb(189, 113, 252);
  text-decoration: underline;
}

.desc {
  color: white;
  font-size: 115%;
}
</style>
