<template>
  <head>
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Grif's Website" />
    <meta property="og:title" content="Grif's Website" />
    <meta property="og:description" content="A website all about Grif." />
    <meta
      property="og:image"
      content="https://cdn.icon-icons.com/icons2/2596/PNG/512/dot_icon_155477.png"
    />
  </head>
  <div id="nav"></div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  padding: 200px 0;
  text-align: center;
  scrollbar-width: none;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.btn {
  display: inline-block;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:hover {
  outline: none;
  background: #000000;
  color: white;
  transform: scale(1.12);
}

.btn:active {
  transform: scale(0.98);
}
.btn-block {
  display: block;
  width: 100%;
}

body {
  background-image: linear-gradient(to right, rgb(25, 0, 255), rgb(255, 0, 98));
}

#nav {
  padding: 30px;
  color: rgb(0, 0, 0);
}

#nav a {
  font-weight: bold;
  color: #ffffff;
}

#nav a.router-link-exact-active {
  color: #bd0000;
}
</style>
