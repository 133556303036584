<template>
  <div class="projects">
    <h1 class="main-text">My Projects</h1>
  </div>
  <div class="desc">
    <p>
      Over the last couple of years, I made cool stuff. Here, you can find all
      of it!
    </p>
    <hr class="divider" />
    <div class="utils">
      <h2 class="sub">Great Utilities (2020 - 2021)</h2>
      <p class="text">
        One of my first serious projects ever - Great Utilities was a private
        utilitarian Discord Bot for one of my old servers. <br />
        The bot itself had a lot of functions, including utility commands,
        ModMail and fun commands.<br />
        Although I really <b>DO NOT</b> recommend this, you can view the
        original bot code on GitHub.
      </p>
      <button @click="goToUtils()" class="gutils">View Code</button>
    </div>
    <hr class="divider" />
    <div class="utils">
      <h2 class="sub">Vuub - Very Useful Utility Box (2021 - 2022)</h2>
      <p class="text">
        My first (and probably only) attempt at making a npm package. <br />
        Vuub was supposed to be a set of very useful tools for JavaScript
        developers to use. Things like subtracting arrays and such. <br />
        Sufficive to say, the whole thing flopped after I lost motivation to
        work on it anymore. <br />
        It was very fun, but I don't think I'll be trying to make a npm package
        again. You can still find it on npm though!
      </p>
      <button @click="goToVuub()" class="gutils">View Package</button>
    </div>
    <hr class="divider" />
    <div class="utils">
      <h2 class="sub">Spark (2021 - Present)</h2>
      <p class="text">
        Spark is another private, utilitarian discord bot for my server Student
        Zone. <br />It has a custom reputation system, role selection menus and
        many more utilitarian and fun commands.<br />
        It is also coded in a much, much better way then Great Utilities and I
        am actually really proud of it. <br />
        Once the rewrite of the bot is complete, it will be open-source!
      </p>
    </div>
    <hr class="divider" />
    <div class="utils">
      <h2 class="sub">Portfolio Website (2022 - Present)</h2>
      <p class="text">
        The website you are looking at right now! Made in Vue.js, it serves as
        my portfolio. <br />
        The website domain is part of the
        <a href="https://www.is-a.dev/" class="link"> is-a.dev</a> project and
        is hosted on
        <a href="https://pages.github.com/" class="link"> GitHub Pages</a>.
        <br />
        Also, the website isn't open-source. I really don't have that kind of
        confidence.
      </p>
    </div>
  </div>
  <button @click="$router.push('/')" class="btn">Go Home</button>
</template>

<script>
export default {
  name: "Projects",
  methods: {
    goToUtils() {
      window.open("https://github.com/GrifTheDev/GreatUtilities");
    },
    goToVuub() {
      window.open("https://www.npmjs.com/package/vuub");
    },
  },
};
</script>

<style scoped>
.main-text {
  color: white;
  font-weight: bold;
  font-size: 50px;
}

.link {
  color: rgb(185, 180, 255);
}

.sub {
  font-size: 25px;
}

.gutils {
  display: inline-block;
  background: rgb(253, 19, 117);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.divider {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  height: 5px;
  background: rgb(255, 255, 255);
  border-radius: 50px;
}

.text {
  font-size: 94%;
}

.desc {
  color: white;
  font-size: 115%;
}
</style>