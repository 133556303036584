<template>
  <body>
    <div class="contact">
      <h1 class="main-text">Get in Touch</h1>
    </div>
    <div class="desc">
      <p>
        If you want to ask me a question, have an inquiry or want a commission -
        look no further then this page! <br />
        Currently, these are the only ways you can reach out to me.
      </p>
      <button @click="goToDiscord()" class="tag1">
        <img
          src="../assets/discord.svg"
          width="20"
          height="15"
          class="discordo"
        />
        Discord
      </button>
      <button @click="goToMail()" class="tag2">📨 E-Mail</button>
      <button @click="goToTheRoll()" class="tag3">📞 Call Me</button>
      <p></p>
    </div>
    <button @click="$router.push('/')" class="btn">Go Home</button>
  </body>
</template>

<script>
export default {
  name: "Contact",
  methods: {
    goToDiscord() {
      window.open("https://discord.gg/r9qs6gC9Ce");
    },
    goToMail() {
      window.location.href = "mailto:grifthedev@gmail.com";
    },
    goToTheRoll() {
      window.open("https://bit.ly/33xgLNQ");
    },
  },
};
</script>

<style scoped>
.main-text {
  color: white;
  font-weight: bold;
  font-size: 50px;
}

.discordo {
  vertical-align: middle;
}

.tag1 {
  display: inline-block;
  background: rgb(88, 101, 242);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag2 {
  display: inline-block;
  background: rgb(253, 11, 104);
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.tag3 {
  display: inline-block;
  background: rgb(195, 52, 252);
  color: rgb(255, 255, 255);
  border: none;
  padding: 8.4px 20px;
  margin: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
.desc {
  color: white;
  font-size: 115%;
}
</style>
