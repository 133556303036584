<template>
  <div class="home">
    <h1 class="main-text">
      Grif is a <span class="word">{{ mainMsg }}</span>
    </h1>
  </div>
  <div class="desc">
    <p>
      Hello, Grif here! I like martial arts, programming, moderation and a good
      conversation. I also like coffee, maybe a bit too much...
      <font-awesome-icon icon="fa-brands fa-discord" />
    </p>
  </div>
  <button @click="$router.push('about')" class="btn">About</button>
  <button @click="$router.push('projects')" class="btn">Projects</button>
</template>

<script>
export default {
  name: "Home",
  data() {
    var items = [
      "developer!",
      "moderator.",
      "student.",
      "coffee addict!",
      "bot.",
      "server owner.",
      "friend to all!"
    ];
    var result = Math.floor(Math.random() * items.length);

    return {
      mainMsg: items[result],
    };
  },
};
</script>

<style>
.main-text {
  color: white;
  font-weight: bold;
  font-size: 50px;
}

.word {
  color: rgb(189, 113, 252);
  text-decoration: underline;
}

.desc {
  color: white;
  font-size: 115%;
}
</style>
